import { ref } from 'vue'
import axios, { isAxiosError, type AxiosResponse } from 'axios'
import useHelpers from './useHelpers'
import { useAgentStore } from '@/stores/agents'
import type { QualificationResults, Qualification } from '@/types/qualification'

const apiBaseUrl = import.meta.env.VITE_API_URL

export default function useQualifications() {
  const allQualifications = ref<Qualification[]>([])
  const selectedQualification = ref<any>()
  const campaignQualificationList = ref<any[]>([])

  const { errorHelper } = useHelpers()
  const { modifyAgent } = useAgentStore()

  async function fetchCampaignQualifications(payload: {
    id: number
    offset?: number
    limit?: number
    with_count?: boolean
  }) {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/campaigns/${payload.id}/qualifications`, {
        params: {
          offset: payload.offset ?? null,
          limit: payload.limit ?? null,
          with_count: payload.with_count ?? null
        }
      })
      campaignQualificationList.value = response.data
    } catch (error: any) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }
  async function fetchAllQualifications(payload?: {
    offset?: number
    limit?: number
    with_count?: boolean
    is_global_only?: boolean
  }) {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/qualifications`, {
        params: {
          offset: payload?.offset ?? null,
          limit: payload?.limit ?? null,
          with_count: payload?.with_count ?? null,
          is_global_only: payload?.is_global_only ?? null
        }
      })
      allQualifications.value = response.data
    } catch (error: any) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }
  async function fetchLearnWorldsCourse(payload: { id: number }) {
    try {
      const response = await axios.post(
        `${apiBaseUrl}/api/qualifications/learnworlds/course-onboard?qualification_id=${payload.id}`
      )
      return response.data
    } catch (error: any) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }
  async function fetchCampaignQualificationDetails({
    id,
    qualification_id
  }: {
    id: number
    qualification_id: number
  }) {
    try {
      const response = await axios.get(
        `${apiBaseUrl}/api/campaigns/${id}/qualifications/${qualification_id}`
      )
      selectedQualification.value = response.data ?? null
    } catch (error: any) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }
  async function fetchQualificationDetails(id: number) {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/qualifications/${id}`)
      selectedQualification.value = response.data ?? null
    } catch (error: any) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }
  async function updateQualificationDetails(qualification: any) {
    try {
      await axios.patch(`${apiBaseUrl}/api/qualifications/${qualification.id}`, qualification)
    } catch (error: any) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }
  async function changeAgentStatus(agentID: number, campaignID: number, status: number) {
    try {
      const response = await axios.patch(
        `${apiBaseUrl}/api/agents/${agentID}/campaigns/${campaignID}`,
        {
          status: status
        }
      )
      if (response?.data)
        modifyAgent({ agent_id: agentID, campaign_id: campaignID, payload: response?.data })
      return response?.data ?? null
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function bulkUpdateCampaignAgentsStatus(campaignID: number, payload: any) {
    try {
      const response = await axios.patch(`${apiBaseUrl}/api/campaigns/${campaignID}/agents/status`, payload)
      return response?.data ?? null
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function addCampaignQualification(campaignID: number, qualificationID: number) {
    try {
      const response = await axios.put(
        `${apiBaseUrl}/api/campaigns/${campaignID}/qualifications/${qualificationID}`,
        {
          order: 1
        }
      )
      return response?.data ?? null
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }
  async function addCustomCampaignQualification(payload: any) {
    try {
      const response = await axios.post(`${apiBaseUrl}/api/qualifications`, {
        ...payload,
        expiration_days: parseInt(payload.expiration_days),
        default_order: 0,
        description: ''
      })
      return response?.data ?? null
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }
  async function removeCampaignQualification(campaignID: number, qualificationID: number) {
    try {
      const response = await axios.delete(
        `${apiBaseUrl}/api/campaigns/${campaignID}/qualifications/${qualificationID}`
      )
      return response?.data ?? null
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function createSignwellResult(id: number) {
    try {
      const response: AxiosResponse<QualificationResults> = await axios.post(
        `${apiBaseUrl}/api/qualification-results/e-signature?qualification_id=${id}&agent_id=me&embedded_signing=true`
      )
      return response.data
    } catch (error: any) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  return {
    campaignQualificationList,
    allQualifications,
    selectedQualification,
    fetchCampaignQualifications,
    fetchAllQualifications,
    fetchCampaignQualificationDetails,
    updateQualificationDetails,
    changeAgentStatus,
    addCampaignQualification,
    removeCampaignQualification,
    addCustomCampaignQualification,
    fetchQualificationDetails,
    fetchLearnWorldsCourse,
    createSignwellResult,
    bulkUpdateCampaignAgentsStatus
  }
}
