<script setup lang="ts">
import BaseDataTable from '@/components/common/DataTable.vue'
import { usePerformanceDashboardStore } from '@/stores/performance-dashboard';
import { storeToRefs } from 'pinia';
import StatCard from '@/components/agent-dashboard/StatCard.vue';

import pb_group_1 from '@/assets/images/pb_group_1.svg'
import pb_group_2 from '@/assets/images/pb_group_2.svg'
import pb_group_3 from '@/assets/images/pb_group_3.svg'
import type { TableOptions } from '@/types/dashboard';
import { ref } from 'vue';
import CampaignApplicationDetails from '@/components/common/CampaignApplicationDetails.vue';

const searchInputPlaceholder = ref('')

const performanceDashboardStore = usePerformanceDashboardStore()
const {
    loading,
    pbSearchQuery,
    pb_count_summary,
    priorityBoarding,
    priorityBoardingTableHeaders
} = storeToRefs(performanceDashboardStore)

const getReliabilityColor = (value: number): string => {
    if (value >= 80) return '#2FAF82'
    if (value >= 50) return '#FFBD3C'
    return '#FF3C3C'
}

const getPBGroupImage = (group: number) => {
    switch (group) {
        case 1:
            return pb_group_1
        case 2:
            return pb_group_2
        case 3:
            return pb_group_3
        default:
            return pb_group_3
    }
}
</script>

<template>
    <section class="tw-flex tw-flex-col tw-gap-y-8">
        <h3 class="tw-text-lg tw-font-black tw-text-shyftoff-purple">
            Priority Boarding
        </h3>

        <div class="tw-flex tw-w-full tw-flex-col tw-gap-4 md:tw-flex-row">
            <template v-if="loading">
                <v-skeleton-loader v-for="(_, index) in [1, 2, 3]" :key="index"
                    class="tw-h-[84px] tw-w-full tw-rounded-lg md:tw-flex-1" />
            </template>

            <template v-else-if="!loading && pb_count_summary">
                <StatCard title="PB1 Count" :value="`${pb_count_summary.pb1?.count || 0}`" class="tw-bg-[#F6F0FF]">
                    <template #substats>
                        <span class="tw-text-xs tw-font-semibold">{{ ((pb_count_summary.pb1?.percentage || 0) * 100).toFixed(0) }}%
                            from total</span>
                    </template>
                </StatCard>

                <StatCard title="PB2 Count" :value="`${pb_count_summary.pb2?.count || 0}`" class="tw-bg-[#F3F2FF]">
                    <template #substats>
                        <span class="tw-text-xs tw-font-semibold">{{ ((pb_count_summary.pb2?.percentage || 0) * 100).toFixed(0) }}%
                            from total</span>
                    </template>
                </StatCard>

                <StatCard title="PB3 Count" :value="`${pb_count_summary.pb3?.count || 0}`" class="tw-bg-[#FFF0F1]">
                    <template #substats>
                        <span class="tw-text-xs tw-font-semibold">{{ ((pb_count_summary.pb3?.percentage || 0) * 100).toFixed(0) }}%
                            from total</span>
                    </template>
                </StatCard>
            </template>
        </div>

        <v-text-field v-if="pb_count_summary && !loading" v-model="pbSearchQuery"
            @update:modelValue="performanceDashboardStore.searchAgent"
            @focus="searchInputPlaceholder = 'Search agents by name or shyftoff ID'" @blur="searchInputPlaceholder = ''"
            :placeholder="searchInputPlaceholder" rounded="lg" class="!tw-flex-none md:tw-max-w-[320px]" label="Search"
            variant="outlined" density="compact" />

        <div class="tw-max-w-full">
            <div v-if="loading || priorityBoarding.loadingSearch">
                <VSkeletonLoader type="table-thead, table-row-divider@7" />
            </div>

            <BaseDataTable v-else-if="priorityBoarding.agents.length && !loading && !priorityBoarding.loadingSearch"
                :items="priorityBoarding.agents" :headers="priorityBoardingTableHeaders"
                :loading="priorityBoarding.loading" :page="priorityBoarding.page"
                :items-per-page="priorityBoarding.itemsPerPage" :items-length="priorityBoarding.total"
                class="tw-max-h-[450px] md:tw-max-h-[800px]"
                @update:options="(options: TableOptions) => performanceDashboardStore.handleTableUpdate(options)">
                <template v-slot:loader>
                    <v-progress-linear color="primary" :height="3" indeterminate />
                </template>

                <template v-slot:headers="{ headers }">
                    <tr>
                        <th v-for="(header, index) in headers[0]" :key="header.title">
                            <div class="tw-flex tw-items-center" :class="{ 'tw-justify-center': index > 3 }">
                                <span class="tw-text-base tw-font-bold tw-capitalize tw-text-regular-text">
                                    {{ header.title }}
                                </span>
                            </div>
                        </th>
                    </tr>
                </template>

                <template v-slot:[`item.agent`]="{ item }">
                    <div class="tw-flex tw-h-full tw-w-full tw-items-center tw-pr-4">
                        <div class="tw-inline-block tw-align-middle">
                            <div
                                class="!tw-mb-0 tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-solid tw-border-grey-border/50 tw-text-center tw-opacity-80">
                                <svg class="tw-inline-block" width="22" height="21" viewBox="0 0 22 21" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M21.4527 19.5313C19.906 16.8572 17.5223 14.9397 14.7405 14.0307C16.1165 13.2116 17.1856 11.9634 17.7835 10.4778C18.3815 8.9923 18.4753 7.35155 18.0505 5.80755C17.6257 4.26355 16.7058 2.90168 15.4321 1.93108C14.1584 0.960478 12.6013 0.434814 10.9999 0.434814C9.39857 0.434814 7.84148 0.960478 6.56778 1.93108C5.29408 2.90168 4.3742 4.26355 3.94941 5.80755C3.52462 7.35155 3.6184 8.9923 4.21636 10.4778C4.81432 11.9634 5.88339 13.2116 7.25939 14.0307C4.4776 14.9387 2.09392 16.8562 0.547127 19.5313C0.490403 19.6238 0.452779 19.7267 0.436473 19.834C0.420168 19.9413 0.425512 20.0507 0.452191 20.1559C0.478869 20.2611 0.526341 20.3598 0.591806 20.4463C0.657271 20.5329 0.739403 20.6054 0.833355 20.6597C0.927308 20.7139 1.03118 20.7488 1.13884 20.7623C1.2465 20.7758 1.35577 20.7676 1.4602 20.7381C1.56462 20.7087 1.6621 20.6586 1.74687 20.5909C1.83164 20.5232 1.90199 20.4392 1.95377 20.3438C3.8672 17.037 7.24924 15.0626 10.9999 15.0626C14.7506 15.0626 18.1327 17.037 20.0461 20.3438C20.0979 20.4392 20.1682 20.5232 20.253 20.5909C20.3378 20.6586 20.4353 20.7087 20.5397 20.7381C20.6441 20.7676 20.7534 20.7758 20.861 20.7623C20.9687 20.7488 21.0726 20.7139 21.1665 20.6597C21.2605 20.6054 21.3426 20.5329 21.4081 20.4463C21.4735 20.3598 21.521 20.2611 21.5477 20.1559C21.5744 20.0507 21.5797 19.9413 21.5634 19.834C21.5471 19.7267 21.5095 19.6238 21.4527 19.5313ZM5.31244 7.75008C5.31244 6.6252 5.646 5.52558 6.27096 4.59027C6.89591 3.65497 7.78417 2.92599 8.82343 2.49551C9.86268 2.06504 11.0062 1.95241 12.1095 2.17186C13.2128 2.39131 14.2262 2.933 15.0216 3.72841C15.817 4.52382 16.3587 5.53723 16.5782 6.6405C16.7976 7.74377 16.685 8.88734 16.2545 9.92659C15.824 10.9658 15.095 11.8541 14.1597 12.4791C13.2244 13.104 12.1248 13.4376 10.9999 13.4376C9.49201 13.436 8.04631 12.8362 6.98005 11.77C5.91379 10.7037 5.31405 9.258 5.31244 7.75008Z"
                                        fill="#2b2e41" />
                                </svg>
                            </div>
                        </div>
                        <p
                            class="tw-ml-4 tw-flex tw-flex-col tw-items-start tw-justify-center tw-text-base tw-text-regular-text">
                            {{ item.agent ? item.agent.name : '-' }}
                        </p>
                    </div>
                </template>

                <template v-slot:[`item.campaign_application`]="{ item }">
                    <div class="tw-flex tw-items-center tw-justify-center">
                        <CampaignApplicationDetails :application="item.campaign_application" />
                    </div>
                </template>

                <template v-slot:[`item.shyftoff_id`]="{ item }">
                    <span
                        class="tw-rounded-full tw-border tw-border-solid tw-border-light-purple tw-px-4 tw-py-1 tw-text-sm tw-font-medium tw-text-regular-text">
                        {{ item.shyftoff_id }}
                    </span>
                </template>

                <template v-slot:[`item.reliability`]="{ item }">
                    <div class="tw-flex tw-gap-x-4">
                        <div class="tw-flex tw-items-center">
                            <v-progress-linear class="tw-w-[90px]" buffer-value="100"
                                :color="getReliabilityColor(item.metrics.reliability.value * 100)"
                                :model-value="item.metrics.reliability.value * 100" :height="11" rounded />
                        </div>
                        <span class="tw-text-base tw-text-[#343434]">
                            {{ (item.metrics.reliability.value * 100).toFixed(0) }}%
                        </span>
                    </div>
                </template>

                <template v-slot:[`item.unscheduled_hours_percent`]="{ item }">
                    <div class="tw-flex tw-items-center tw-justify-center">
                        <span class="tw-text-base tw-text-[#343434]">
                            {{ (item.metrics.unscheduled_hours_percent.value * 100).toFixed(0) }}%
                        </span>
                    </div>
                </template>

                <template v-slot:[`item.quality`]="{ item }">
                    <div class="tw-flex tw-items-center tw-justify-center">
                        <span class="tw-text-base tw-text-[#343434]">
                            {{ item.metrics.quality.value !== null ? item.metrics.quality.value : '-' }}
                        </span>
                    </div>
                </template>

                <template v-slot:[`item.efficiency`]="{ item }">
                    <div class="tw-flex tw-items-center tw-justify-center">
                        <span class="tw-text-base tw-text-[#343434]">
                            {{ (item.metrics.efficiency.value * 100).toFixed(0) }}%
                        </span>
                    </div>
                </template>

                <template v-slot:[`item.momentum`]="{ item }">
                    <div class="tw-flex tw-items-center tw-justify-center">
                        <span class="tw-text-base tw-text-[#343434]">
                            {{ item.metrics.momentum.value !== null ? item.metrics.momentum.value.toFixed(0) : '-' }}
                        </span>
                    </div>
                </template>

                <template v-slot:[`item.priority_boarding_group`]="{ item }">
                    <div class="tw-flex tw-items-center tw-justify-center tw-gap-x-2">
                        <img :src="getPBGroupImage(item.priority_boarding_group)"
                            :alt="`PB Group ${item.priority_boarding_group}`" class="tw-h-8 tw-w-8" />
                    </div>
                </template>

            </BaseDataTable>

            <template v-else>
                <h5 class="tw-my-6 tw-mt-8 tw-text-center tw-text-lg tw-font-extrabold tw-text-darker-light-text">
                    No data available.
                </h5>
                <img src="@/assets/images/empty-state--tables.svg"
                    class="tw-relative tw-left-1/2 tw-block !tw-min-h-[160px] tw-max-w-full -tw-translate-x-1/2 tw-scale-90 tw-object-cover sm:!tw-min-h-[270px]"
                    alt="">
            </template>
        </div>
    </section>
</template>