import { defineStore, type StateTree } from 'pinia'
import useQualifications from '@/composables/useQualifications'
import router from '@/router'
import useUser from '@/composables/useUser'
import type { Qualification } from '@/types/qualification'

type OnboardingSteps = {
  id: number
  qualification_id: number
  label: string
  component: any
  completed: boolean
  exclude: boolean
  parameters?: Record<string, any>
}

type StepsInfo = {
  id: number
  label: string
  component: string | null
  getParameters?: (qualification: any) => Record<string, any>
}

const ONBOARDING_STEPS_INFO: Record<number, StepsInfo> = {
  5: {
    id: 1,
    label: 'ID Verification',
    component: 'Id-Verification'
  },
  4: {
    id: 2,
    label: 'VideoAsk',
    component: 'VideoAsk',
    getParameters: (qualification: Qualification) => ({
      videoask_link: qualification.parameters?.videoask_link || null
    })
  },
  3: {
    id: 3,
    label: 'Contract Signing',
    component: 'E-Signature'
  }
}

export const useOnboardingStore = defineStore('onboarding', {
  state: () => ({
    currentStep: 0,
    totalSteps: 0,
    onboardingCompleted: false,
    onboardingLoading: false,
    onboardingError: false,
    onboardingErrorMsg: '',
    onboardingQualificationsIds: [] as number[],
    onboardingSteps: [] as OnboardingSteps[]
  }),
  getters: {
    progress: (state: StateTree) => ((state.currentStep + 1) / state.totalSteps) * 100,
    isLastStep: (state: StateTree) => state.currentStep + 1 === state.totalSteps
  },
  actions: {
    nextStep() {
      if (this.currentStep + 1 < this.totalSteps) {
        this.currentStep += 1
      }

      if (this.isLastStep) {
        this.onboardingCompleted = true
        router.replace('/dashboard')
      }
    },
    setOnboardingLoading(value: boolean) {
      this.onboardingLoading = value
    },
    setOnbordingError(msg: string) {
      this.onboardingErrorMsg = msg
      this.onboardingError = true

      setTimeout(() => {
        this.onboardingError = false
      }, 3000)
    },
    async checkOnboardingProcess() {
      const { userDetails, getUserDetails } = useUser()
      const { allQualifications, fetchAllQualifications } = useQualifications()

      try {
        await getUserDetails()

        // Fetch global qualifications
        await fetchAllQualifications({ is_global_only: true })
        const qualificationsIds = allQualifications.value.map((qualification) => qualification.id)
        this.onboardingQualificationsIds = qualificationsIds

        // If there is no global qualification, skip onboarding
        if (!this.onboardingQualificationsIds.length) {
          this.onboardingCompleted = true
          return
        }

        // Set total steps base in the number of global qualifications active
        this.totalSteps = this.onboardingQualificationsIds.length + 1

        // Set onboarding steps dynamically
        this.onboardingSteps = allQualifications.value.map((qualification) => {
          const getParameters = ONBOARDING_STEPS_INFO[qualification.type].getParameters

          const newOnboardingStep: OnboardingSteps = {
            id: ONBOARDING_STEPS_INFO[qualification.type].id,
            qualification_id: qualification.id,
            label: ONBOARDING_STEPS_INFO[qualification.type].label,
            component: ONBOARDING_STEPS_INFO[qualification.type].component,
            completed: false,
            exclude: false,
          }

          if (getParameters) {
            newOnboardingStep.parameters = getParameters(qualification)
          }

          return newOnboardingStep
        })

        // Sort onboarding steps to show in the correct order
        this.onboardingSteps.sort((a, b) => a.id - b.id)

        // Push last step into onboarding steps
        this.onboardingSteps.push({
          id: 0,
          qualification_id: 0,
          label: 'Start using ShyftOff',
          component: null,
          completed: true,
          exclude: false,
        })

        // Flag onboarding qualifications that doesn't need to be shown to the user
        this.onboardingSteps.forEach((step) => {
          if (!this.onboardingQualificationsIds.includes(step.qualification_id) && step.id !== 0)
            step.exclude = true
        })

        // Get user qualifications that match the global qualifications
        const user = userDetails.value

        const userQualifications = user.agent?.qualifications.filter((q) =>
          this.onboardingQualificationsIds.includes(q.qualification?.id ?? 0)
        )

        // If user has no qualifications, onboarding start on step 1
        if (!userQualifications) {
          this.currentStep = 0
          this.onboardingCompleted = false
          return
        }

        // Update onboarding steps completion based on user's qualifications
        this.onboardingSteps.forEach((step) => {
          if (step.id !== 0) {
            const currentQualification = userQualifications.find(
              (q) => q.qualification?.id === step.qualification_id
            )

            step.completed = currentQualification?.is_qualified ?? false
          }
        })

        const filteredSteps = this.onboardingSteps.filter((step) => !step.exclude)

        // Check if all filtered steps are complete, so we skip the onboarding
        if (filteredSteps.every((step) => step.completed)) {
          this.onboardingCompleted = true
          return
        }

        this.currentStep = filteredSteps.findIndex((step) => !step.completed)
        this.onboardingCompleted = false
      } catch (error) {
        this.setOnbordingError('Error checking onboarding process')
      }
    }
  },
  persist: true
})
