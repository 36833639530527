export const isNullOrEmpty = (object: object | null) => {
  return object === null || (Object.keys(object).length === 0 && object.constructor === Object)
}

export const objectHasKeys = (obj: Record<string, any>, keys: string[]): boolean => {
  return keys.every(key => key in obj);
}

export const convertDataToDownloadableFile = (data: string, fileName: string, fileType: string) => {
  const blob = new Blob([data], { type: fileType });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.style.display = 'none';
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}
