import { AxiosError } from 'axios'
import type { ErrorDetail } from '@/types/error'

export default function useHelpers() {
  let timeout: ReturnType<typeof setTimeout>
  function debounce(callback: () => void, delay = 300) {
    clearTimeout(timeout)
    timeout = setTimeout(callback, delay)
  }

  let throttlePause: boolean = false
  function throttle(callback: () => void, interval: number) {
    if (throttlePause) return
    throttlePause = true
    setTimeout(() => {
      callback();
      throttlePause = false
    }, interval)
  }

  function errorHelper(error: AxiosError) {
    console.log('errorHelper was called')// temporary left to test sentry
    if ('detail' in error) {
      const errorDetail = error.detail as ErrorDetail | string
      if (typeof errorDetail === 'string') {
        throw new Error(errorDetail)
      } else if (Array.isArray(errorDetail)) {
        if (errorDetail.length) {
          throw new Error(errorDetail[0].msg)
        }
      } else if (typeof errorDetail === 'object' && errorDetail !== null) {
        if ('message' in errorDetail) {
          throw new Error(errorDetail.message)
        }
      }
    }
  }

  function dateWithoutTimezone(date: Date|string|number) {
    const noTimezoneDate = date instanceof Date ? new Date(date.toISOString().slice(0, -1)) : new Date(new Date(date).toISOString().slice(0, -1))
    return noTimezoneDate
  }

  function formatPhoneNumber(phoneNumberString: string) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  return {
    debounce,
    throttle,
    errorHelper,
    dateWithoutTimezone,
    formatPhoneNumber
  }

}
