<script setup lang="ts">
import { ref } from 'vue'
import { useProfileStore } from '@/stores/profile'
import useZendesk from '@/composables/useZendesk'

const props = defineProps<{
  chatId?: string | null
}>()

const profileStore = useProfileStore()
const { sendTicket, uploadAttachment } = useZendesk()

const supportFormEmail = ref(profileStore.profile.email)
const supportFormMessage = ref('')
const supportFormSubject = ref('')
const attachment = ref()
const loadingState = ref(false)
const formHasErrors = ref(false)
const showSnackbar = ref(false)

const emitSubmitFinished = defineEmits<{
  (e: 'update:handleShowForm', value: boolean): void;
}>()

async function uploadFile(file: File[] | null) {
  if (file != null && Array.isArray(file) && file.length) {
    attachment.value = file[0]
  } else {
    attachment.value = null
  }
}

async function sendNewSupportTicket() {
    try {
      loadingState.value = true
      let ticketPayload: { 
        message: string; 
        email: string; 
        subject: string; 
        attachments: any[]; 
        uploads: string[];
        chat_id?: string;
      } = {
        message: supportFormMessage.value,
        email: supportFormEmail.value,
        subject: supportFormSubject.value,
        attachments: [],
        uploads: []
      }

      if (props.chatId) {
        ticketPayload.chat_id = props.chatId;
      }

      if (attachment.value) {
        const blob = new Blob([attachment.value], { type: attachment.value.type })
        const blobArrayBuffer = await blob.arrayBuffer()
        const attachmentResponse = await uploadAttachment({ data: blobArrayBuffer, name: attachment.value.name, type: attachment.value.type})
        if (attachmentResponse.attachments.length) {
          ticketPayload['attachments'] = attachmentResponse.attachments
          ticketPayload.uploads.push(attachmentResponse?.token)
        }
      }

      await sendTicket(ticketPayload)
      supportFormMessage.value = ''
      supportFormSubject.value = ''
      attachment.value = null
      showSnackbar.value = true
      loadingState.value = false

      setTimeout(() => {
        emitSubmitFinished('update:handleShowForm', false)
      }, 3000)
    } catch (error) {
      loadingState.value = false
    }
}
</script>
<template>
  <v-form @submit.prevent class="tw-flex tw-flex-col tw-gap-y-4">
    <VTextField
      v-model="supportFormEmail"
      rounded="lg"
      class="border-radius-override"
      label="Email"
      variant="outlined"
      density="comfortable"
      :disabled="true"
    />
    <VTextField
      v-model="supportFormSubject"
      rounded="lg"
      class="border-radius-override"
      label="Subject"
      variant="outlined"
      density="comfortable"
    />
    <v-textarea
      variant="outlined"
      label="Message"
      auto-grow
      rounded="lg"
      v-model="supportFormMessage"
    ></v-textarea>
    <v-file-input @update:modelValue="uploadFile" accept="image/*" class="!tw-pointer-events-auto tw-relative tw-cursor-pointer" flat variant="outlined" rounded="lg" prepend-icon="">
      <template #append-inner>
        <div class="tw-pointer-events-auto tw-absolute tw-left-0 tw-top-0 tw-z-10 tw-h-full tw-w-full tw-cursor-pointer tw-px-3 tw-leading-[48px]">
          <i class="mdi mdi-paperclip -tw-mt-1 tw-mr-1 tw-inline-block tw-align-middle tw-text-xl tw-leading-none tw-text-shyftoff-purple"></i>
          <span v-if="attachment == null">
            Attachment
          </span>
          <span v-else>
            {{ (attachment as File).name }}
          </span>
        </div>
      </template>
    </v-file-input>
    <VBtn @click="sendNewSupportTicket" color="primary" size="large" :loading="loadingState" :disabled="loadingState || formHasErrors || !supportFormMessage || !supportFormSubject" rounded="lg" class="px-7 tw-mb-0 tw-mt-3 tw-w-full">
      Send
    </VBtn>
  </v-form>
  <VSnackbar v-model="showSnackbar" :timeout="3000" :color="'green-darken-2'" class="tw-ml-[70px] xl:tw-ml-[250px]">
    Ticket created succesfully!
  </VSnackbar>
</template>

<style lang="scss" scoped>
::v-deep(.v-file-input) {
    .v-field {
      @apply tw-pr-0 tw-min-h-[48px] #{!important};
      &.v-field--focused {
        .v-field__outline {
          .v-field__outline__start,
          .v-field__outline__end {
            @apply tw-border;
          }
          .v-field__outline__start {
            @apply tw-border-r-0;
          }
          .v-field__outline__end {
            @apply tw-border-l-0;
          }
        }
      }
      .v-field__field {
        input {
          @apply tw-cursor-pointer;
        }
        .v-field__input {
          @apply tw-hidden;
        }
      }
      .v-field__clearable {
        @apply tw-relative tw-z-20 tw-px-2;
      }
      &.v-field--dirty {
        @apply tw-bg-gray-100;
        .v-field__outline {
          .v-field__outline__start,
          .v-field__outline__end {
            @apply tw-border-none #{!important};
          }
        }
      }
      .v-field__outline {
        .v-field__outline__start,
        .v-field__outline__end {
          @apply tw-border-dashed tw-border-grey-border #{!important};
        }
      }
      &:hover {
        &.v-field--dirty {
          @apply tw-bg-gray-200;
        }
        .v-field__outline {
          .v-field__outline__start,
          .v-field__outline__end {
            @apply tw-border-shyftoff-purple/50 #{!important};
          }
        }
        .v-field__append-inner {
          div {
            @apply tw-opacity-100;
          }
        }
      }
    }

  }
</style>
